import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _68854cce = () => interopDefault(import('../pages/check-email.vue' /* webpackChunkName: "pages/check-email" */))
const _41f91c0d = () => interopDefault(import('../pages/ian.vue' /* webpackChunkName: "pages/ian" */))
const _0511a7e0 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _2b9746ba = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _10db2d7e = () => interopDefault(import('../pages/medications/index.vue' /* webpackChunkName: "pages/medications/index" */))
const _7dce943c = () => interopDefault(import('../pages/password-updated.vue' /* webpackChunkName: "pages/password-updated" */))
const _5b3ee66d = () => interopDefault(import('../pages/paylinks/index.vue' /* webpackChunkName: "pages/paylinks/index" */))
const _5bf0a8da = () => interopDefault(import('../pages/prescriptions/index.vue' /* webpackChunkName: "pages/prescriptions/index" */))
const _c0203608 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _7f70fdca = () => interopDefault(import('../pages/registered.vue' /* webpackChunkName: "pages/registered" */))
const _2971f884 = () => interopDefault(import('../pages/resend-confirmation.vue' /* webpackChunkName: "pages/resend-confirmation" */))
const _274f401c = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _2593b27c = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _0e0002f6 = () => interopDefault(import('../pages/settings/close-account.vue' /* webpackChunkName: "pages/settings/close-account" */))
const _29a5b557 = () => interopDefault(import('../pages/settings/export.vue' /* webpackChunkName: "pages/settings/export" */))
const _525200a9 = () => interopDefault(import('../pages/settings/hospitals/index.vue' /* webpackChunkName: "pages/settings/hospitals/index" */))
const _763af28b = () => interopDefault(import('../pages/settings/options.vue' /* webpackChunkName: "pages/settings/options" */))
const _59bbbb98 = () => interopDefault(import('../pages/settings/pharmacies.vue' /* webpackChunkName: "pages/settings/pharmacies" */))
const _0d7747ba = () => interopDefault(import('../pages/settings/security.vue' /* webpackChunkName: "pages/settings/security" */))
const _8d0f1ea4 = () => interopDefault(import('../pages/settings/user.vue' /* webpackChunkName: "pages/settings/user" */))
const _303fee37 = () => interopDefault(import('../pages/settings/hospitals/new.vue' /* webpackChunkName: "pages/settings/hospitals/new" */))
const _59789f14 = () => interopDefault(import('../pages/settings/hospitals/_id/index.vue' /* webpackChunkName: "pages/settings/hospitals/_id/index" */))
const _d52d5c90 = () => interopDefault(import('../pages/settings/hospitals/_id/edit.vue' /* webpackChunkName: "pages/settings/hospitals/_id/edit" */))
const _ea59d1d6 = () => interopDefault(import('../pages/embed/rx.vue' /* webpackChunkName: "pages/embed/rx" */))
const _58574ba1 = () => interopDefault(import('../pages/prescriptions/new.vue' /* webpackChunkName: "pages/prescriptions/new" */))
const _2af7a9c6 = () => interopDefault(import('../pages/redirect/new.vue' /* webpackChunkName: "pages/redirect/new" */))
const _3b6a04c9 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _59884525 = () => interopDefault(import('../pages/paylinks/_slug.vue' /* webpackChunkName: "pages/paylinks/_slug" */))
const _da3e6c70 = () => interopDefault(import('../pages/prescriptions/_signature.vue' /* webpackChunkName: "pages/prescriptions/_signature" */))
const _eafea9ac = () => interopDefault(import('../pages/new-password/_uid/_token.vue' /* webpackChunkName: "pages/new-password/_uid/_token" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/check-email",
    component: _68854cce,
    name: "check-email"
  }, {
    path: "/ian",
    component: _41f91c0d,
    name: "ian"
  }, {
    path: "/login",
    component: _0511a7e0,
    name: "login"
  }, {
    path: "/logout",
    component: _2b9746ba,
    name: "logout"
  }, {
    path: "/medications",
    component: _10db2d7e,
    name: "medications"
  }, {
    path: "/password-updated",
    component: _7dce943c,
    name: "password-updated"
  }, {
    path: "/paylinks",
    component: _5b3ee66d,
    name: "paylinks"
  }, {
    path: "/prescriptions",
    component: _5bf0a8da,
    name: "prescriptions"
  }, {
    path: "/register",
    component: _c0203608,
    name: "register"
  }, {
    path: "/registered",
    component: _7f70fdca,
    name: "registered"
  }, {
    path: "/resend-confirmation",
    component: _2971f884,
    name: "resend-confirmation"
  }, {
    path: "/reset-password",
    component: _274f401c,
    name: "reset-password"
  }, {
    path: "/settings",
    component: _2593b27c,
    name: "settings",
    children: [{
      path: "close-account",
      component: _0e0002f6,
      name: "settings-close-account"
    }, {
      path: "export",
      component: _29a5b557,
      name: "settings-export"
    }, {
      path: "hospitals",
      component: _525200a9,
      name: "settings-hospitals"
    }, {
      path: "options",
      component: _763af28b,
      name: "settings-options"
    }, {
      path: "pharmacies",
      component: _59bbbb98,
      name: "settings-pharmacies"
    }, {
      path: "security",
      component: _0d7747ba,
      name: "settings-security"
    }, {
      path: "user",
      component: _8d0f1ea4,
      name: "settings-user"
    }, {
      path: "hospitals/new",
      component: _303fee37,
      name: "settings-hospitals-new"
    }, {
      path: "hospitals/:id",
      component: _59789f14,
      name: "settings-hospitals-id"
    }, {
      path: "hospitals/:id/edit",
      component: _d52d5c90,
      name: "settings-hospitals-id-edit"
    }]
  }, {
    path: "/embed/rx",
    component: _ea59d1d6,
    name: "embed-rx"
  }, {
    path: "/prescriptions/new",
    component: _58574ba1,
    name: "prescriptions-new"
  }, {
    path: "/redirect/new",
    component: _2af7a9c6,
    name: "redirect-new"
  }, {
    path: "/",
    component: _3b6a04c9,
    name: "index"
  }, {
    path: "/paylinks/:slug",
    component: _59884525,
    name: "paylinks-slug"
  }, {
    path: "/prescriptions/:signature",
    component: _da3e6c70,
    name: "prescriptions-signature"
  }, {
    path: "/new-password/:uid?/:token?",
    component: _eafea9ac,
    name: "new-password-uid-token"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
